import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Cookies = ({ siteTitle }) => (
  <div role="dialog" id="cookieNotification" className="notification">
    <div className="notification__content">
      <h2 className="notification__title">
        We use cookies to help improve user experience.
      </h2>
      <p className="notification__message">
        This includes a range of things such as user personalisation, page
        analytics and more.
      </p>
      <div className="notification__footer">
        <a
          target="_blank"
          title="Privacy Policy"
          href=""
          className="notification__button -primary"
        >
          Learn More
        </a>
        <div id="js-cookie-button" className="notification__button -secondary">
          Close
        </div>
      </div>
    </div>
  </div>
)

Cookies.propTypes = {
  siteTitle: PropTypes.string,
}

Cookies.defaultProps = {
  siteTitle: ``,
}

export default Cookies

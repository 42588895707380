import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Footer = () => (
  <footer id="footer">
    <div class="container">
      <div class="row align-items-lg-center">
        <div class="col-12 col-lg-5 col-xl-4 order-1 order-md-1 order-lg-3">
          <nav class="social-nav"></nav>
        </div>
        <div class="col-12 col-md-4 d-md-none order-2 order-md-3 order-lg-2">
          <nav class="footer-nav"></nav>
        </div>
        <div class="col-12 col-md-12 col-lg-7 col-xl-8 order-3 order-md-2 order-lg-1">
          <div class="row align-items-end align-items-lg-center">
            <div class="col-5 col-md-3 col-lg-3 col-xl-2">
              <strong class="logo">
                <a href="https://www.activatelearning.ac.uk/"></a>
              </strong>
            </div>
            <div class="col-7 col-md-9 copy col-lg-9 col-xl-10">
              <p>
                Copyright Activate Learning {new Date().getFullYear()}. All
                rights reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer

import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"

const Header = (props) => {
  const [showMobileNav, setShowMobileNav] = useState(false)

  function toogleMobileNav() {
    if (showMobileNav) {
      setShowMobileNav(false)
    } else {
      setShowMobileNav(true)
    }
  }

  return (
    <header id="navBar">
      <div id="scriptsWrap"></div>
      <div className="breadcrumbs-bar d-none d-xl-block">
        <div className="left-blox"></div>
      </div>
      <div className="header-main">
        <div className="container">
          <div className="header-main-body">
            <div className="row">
              <div className="col-xl-7">
                <div className="logo-box">
                  <div
                    className="logo bg-img"
                    className={
                      showMobileNav ? "logo bg-img active" : "logo bg-img"
                    }
                  >
                    <a href="https://www.activatelearning.ac.uk/">
                      Activate Learning
                    </a>
                    <img className="d-none d-md-block" alt="" src="logo.svg" />
                    <img className="d-block d-md-none" alt="" src="logo.svg" />
                  </div>
                </div>
                <nav
                  className="main-nav"
                  role="navigation"
                  aria-label="Desktop Navigation"
                >
                  <ul id="menu-primary-navigation" className="">
                    <li
                      id="menu-item-841"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-841 has-drop"
                    >
                      <a href="https://www.activatelearning.ac.uk/about-us/work-with-us">
                        Work with us
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div class="col-xl-5 d-none d-xl-flex justify-content-xl-end">
                <nav class="social-nav">
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/ActivateLearn/"
                        target="_blank"
                        rel="nofollow noopener"
                      >
                        <span class="fab icon facebook f"></span>
                        <span class="sr-only">Facebook</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/channel/UCbC6CyLMzwwI1XyEywlg6yA"
                        target="_blank"
                        rel="nofollow noopener"
                      >
                        <span class="fab fa-youtube icon youtube"></span>
                        <span class="sr-only">YouTube</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/activatelearn"
                        target="_blank"
                        rel="nofollow noopener"
                      >
                        <span class="fab fa-instagram icon instagram"></span>
                        <span class="sr-only">Instagram</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/Activate_Learn"
                        target="_blank"
                        rel="nofollow noopener"
                      >
                        <span class="fab fa-twitter icon twitter"></span>
                        <span class="sr-only">Twitter</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/school/activate-learning/"
                        target="_blank"
                        rel="nofollow noopener"
                      >
                        <span class="fab fa-linkedin icon linkedin"></span>
                        <span class="sr-only">LinkedIn</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between mobile-nav">
          <a className="align-self-center"></a>
          <div className="align-self-center nav-desktop"></div>

          <a
            href="#"
            onClick={() => toogleMobileNav()}
            className={showMobileNav ? "open open-button" : "open-button"}
          >
            <span></span>
            <span></span>
            <span></span>
          </a>
          <div className={showMobileNav ? "open mobile-menu" : "mobile-menu"}>
            <nav
              id="mobile-menus"
              className="mobile-menus d-xl-none"
              role="navigation"
              aria-label="mobile navigation"
              style={{ marginRight: "0px" }}
            >
              <ul id="menu-primary-navigation-1" className="">
                <li
                  className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-766 current_page_item menu-item-has-children menu-item-841 has-drop"
                  style={{ zIndex: "3" }}
                >
                  <a
                    href="https://www.activatelearning.ac.uk/about-us/work-with-us"
                    aria-current="page"
                  >
                    Work with us
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="breadcrumbs-bar d-none d-xl-block">
        <div className="container"> </div>
      </div>
    </header>
  )
}

export default Header
